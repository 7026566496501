import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import Grid, { Row } from '@components/Grid';
import Card, { CardImage } from '@components/Card';
import Container from '@components/Container';
import BottomCta from '@components/Home/BottomCta';
import { graphql } from 'gatsby';
import {
  LanaQuery,
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
} from '../../graphql-types';

import SEO from '@components/seo';
import useContentfulImage from '../hooks/useContentfulImage.hook';
import { AppDownloadUrl } from '@src/pageConstants';
import useDialogRoute from '@components/Dialog/useDialogRoute.hook';
import appenPages from '@src/data/appenPages';
import ProductHero from '@src/components/ProductHero';
import SnapScrollContainer from '@src/components/SnapScrollContainer';
import Footer from '@src/components/Footer/Footer';
import * as styles from './lana.module.scss';
import * as lanStyles from './lan.module.scss';
import StickyFooter from '@src/components/StickyFooter/StickyFooter';
import AppRatings from '@src/components/Home/AppRatings';
import useIsMobile from '../hooks/useIsMobile.hook';
import DownloadAppButton from '@src/components/DownloadAppButton';
import UspList, { UspListItem } from '@src/components/UspList/UspList';
import PhoneSvg from '../icons/2.0/illustrations/Phone.svg';
import SwipeableCard from '@src/components/SwipeableCard';
import Badge from '@src/components/Badge/Badge';
import WideCardWithCarousel from '@src/components/WideCardWithCarousel';
import Testimonial1 from '../images/lan/Testimonial1.png';
import Testimonial2 from '../images/lan/Testimonial2.png';
import Testimonial3 from '../images/lan/Testimonial3.png';
import Testimonial4 from '../images/lan/Testimonial4.png';
import Testimonial5 from '../images/lan/Testimonial5.png';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import { navigate } from 'gatsby';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';

type LanaProps = {
  data: LanaQuery;
};

function Lana({ data }: LanaProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      heroImage,
      pageContent,
    },
  } = data;

  const collectLoansCard = pageContent[0] as ContentfulCard;

  const negotiateCard = pageContent[1] as ContentfulCard;
  const negotiateCardImg = useContentfulImage(negotiateCard.backgroundImage);
  const loanBrokersDialog = useDialogRoute('langivare');
  const loanBrokerDialogContent = pageContent[2] as ContentfulDialogContent;
  const downloadAppCard = pageContent[3] as ContentfulCard;
  const compareCard = pageContent[4] as ContentfulCard;
  const jamforDialogData = pageContent[5] as ContentfulDialogContent;
  const jamforDialog = useDialogRoute('prisgaranti');
  const testimonialImages = [
    Testimonial1,
    Testimonial2,
    Testimonial3,
    Testimonial4,
    Testimonial5,
  ];

  const uspList = (pageContent[6] as ContentfulTextList).advancedTextListItems;

  const collectLoansCardImg = useContentfulImage(
    collectLoansCard.backgroundImage,
  );
  const heroImg = useContentfulImage(heroImage);

  const isMobile = useIsMobile();

  return (
    <SnapScrollContainer>
      <Layout theme="white" subNavLinks={appenPages} customFooter={<></>}>
        <SEO title={headLine} />
        <Container>
          <ProductHero
            title="Låna smartare."
            subtitle="Inte mer."
            image={heroImg}
          >
            <UspList items={uspList as UspListItem[]} />
            <DownloadAppButton />
            {!isMobile && <AppRatings />}
          </ProductHero>
          <Grid spacedRows>
            <Row>
              <Card
                title={collectLoansCard.title}
                text={collectLoansCard.subtitle}
                cta={collectLoansCard.linkText}
                ctaHref={collectLoansCard.linkPath}
                contentfulImg={collectLoansCardImg}
                onClick={() => navigate(collectLoansCard.linkPath)}
                wide
                theme="soft"
                snap={true}
              />
            </Row>
            <Card
              title={negotiateCard.title}
              text={negotiateCard.subtitle}
              cta={negotiateCard.linkText}
              onClick={loanBrokersDialog.openDialog}
              theme="soft"
            >
              <CardImage imageData={negotiateCardImg} />
            </Card>
            <Card theme="dark-purple" centerContent>
              <div className={lanStyles.callCard}>
                <PhoneSvg className={lanStyles.phoneSvg} />
                <h3 style={{ textAlign: 'center', fontSize: '36px' }}>
                  Vill du hellre bli guidad?
                </h3>
                <p style={{ textAlign: 'center' }}>
                  Lån kan vara en djungel. Men våra kredithandläggare kan hjälpa
                  dig fram till din bästa ansökan.
                </p>
                <div
                  style={{
                    color: '#FCD4F3',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  <PrimaryButton
                    color="purple"
                    href="https://calendly.com/d/39q-kvg-t3w/telefonsamtal-rocker"
                  >
                    Ring mig
                  </PrimaryButton>
                </div>
              </div>
            </Card>

            <SwipeableCard
              theme="dark-purple"
              badge={<Badge color="red">Hur det går till!</Badge>}
              cardItems={[
                {
                  title: 'Gör en ansökan',
                  text:
                    'Fyll i en digital ansökan. Det tar inte längre än någon minut och det tas aldrig mer än en kreditupplysning',
                  imgPath: <div className={lanStyles.applyStepsBadge}>1</div>,
                },
                {
                  title: 'Ladda ner appen och ta emot anbud',
                  text:
                    'Luta dig tillbaka i soffan och vänta på att anbuden från våra partners strömmar in i Rocker-appen.',
                  imgPath: <div className={lanStyles.applyStepsBadge}>2</div>,
                },
                {
                  title: 'Jämför och välj ditt bästa bud',
                  text:
                    'Vissa erbjuder högre lånebelopp, andra lägre ränta eller kanske längre löptid. Hitta det som känns rätt för dig. Och du, tveka inte att ringa oss ifall du behöver hjälp.',
                  imgPath: <div className={lanStyles.applyStepsBadge}>3</div>,
                },
              ]}
            ></SwipeableCard>
            <Card
              title={downloadAppCard.title}
              text={downloadAppCard.subtitle}
              cta={downloadAppCard.linkText}
              ctaHref={AppDownloadUrl}
              theme="soft"
              badge={<Badge color="pink">Just nu!</Badge>}
            >
              <CardImage
                imageData={useContentfulImage(downloadAppCard.backgroundImage)}
              />
            </Card>
            <Row>
              <WideCardWithCarousel
                title={compareCard.title}
                text={compareCard.subtitle}
                cta={compareCard.linkText}
                ctaHref={compareCard.linkPath}
                theme="soft-2"
                carouselImages={testimonialImages}
              />
            </Row>
          </Grid>
        </Container>
        <ContentfulDialog
          contentfulData={jamforDialogData}
          visible={jamforDialog.isOpen}
          onClose={jamforDialog.closeDialog}
        />
        <ContentfulDialog
          contentfulData={loanBrokerDialogContent}
          visible={loanBrokersDialog.isOpen}
          onClose={loanBrokersDialog.closeDialog}
        />
        <div className={styles.bottomContainer}>
          <BottomCta />
          <Footer />
        </div>
        <StickyFooter />
      </Layout>
    </SnapScrollContainer>
  );
}

export default Lana;

export const query = graphql`
  query Lana($locale: String) {
    contentfulPage(pageUrl: { eq: "/lana" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
